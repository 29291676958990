import React, { useState } from "react";
import { Link } from "gatsby";

import { IconMenu } from "../atoms/Icons";

const NavLink = ({ to, name }) => (
  <Link
    activeClassName="font-semibold"
    className="hover:font-semibold hover:underline z-10"
    to={to}
  >
    {name}
  </Link>
);

const MenuLink = ({ to, name }) => (
  <Link activeClassName="font-bold" to={to}>
    {name}
  </Link>
);

const Navbar = () => {
  const [display, setDisplay] = useState("hidden");

  const handleBlur =
    display === "hidden"
      ? null
      : "z-40 absolute h-screen w-full backdrop-blur-sm";

  return (
    <div className={handleBlur}>
      {/*  Mobile  */}
      <div
        role="button"
        aria-label="menu button"
        tabIndex="0"
        onClick={() =>
          display === "hidden" ? setDisplay("block") : setDisplay("hidden")
        }
        className="relative flex items-end justify-end lg:hidden px-4 pb-20"
      >
        <IconMenu display={display} />
        <div
          className={`${display} z-10 bg-mainBackground absolute top-0 right-0 navbar flex flex-col lg:hidden items-start justify-between rounded-lg shadow-md px-8 pb-8 pt-16 space-y-6`}
        >
          <MenuLink to="/" name="Hello!" />
          <MenuLink to="/about" name="About Dana" />
          <MenuLink to="/juicyPodcast" name="The Juicy Pause Podcast" />
          <MenuLink to="/blog" name="Blog" />
          <MenuLink to="/events" name="Working with Dana" />
          <MenuLink to="/store" name="Store" />
          <MenuLink to="/friends" name="Juicy Friends" />
        </div>
      </div>

      {/* Desktop */}
      <div className="navbar hidden lg:flex items-center justify-between w-full py-6 px-8">
        <NavLink to="/" name="Hello!" />
        <NavLink to="/about" name="About Dana" />
        <NavLink to="/juicyPodcast" name="The Juicy Pause Podcast" />
        <NavLink to="/blog" name="Blog" />
        <NavLink to="/events" name="Working with Dana" />
        <NavLink to="/store" name="Store" />
        <NavLink to="/friends" name="Juicy Friends" />
      </div>
    </div>
  );
};

export default Navbar;
